<template>
  <BaseList
    :list-query="materialTypes"
    route="materialType"
    locale-section="pages.materialTypes"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "MaterialTypesList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      materialTypes: gql`
        query materials {
          list: materialTypes {
            id
            name: materialType
            materialType
          }
        }
      `
    };
  }
};
</script>
